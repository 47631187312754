import React, { useCallback, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as SessionSetupScreen from '../../views/SessionSetup/config'

import * as sessionActions from '../../metrics_server/sessions/actions'
import * as teamActions from '../../metrics_server/teams/actions'
import * as pitchActions from '../../metrics_server/pitches/actions'

// MS Types
import {
  RawSessionData,
  SessionsState
} from '../../metrics_server/sessions/types'
import { TeamsState } from '../../metrics_server/teams/types'
import { PlayersState } from '../../metrics_server/players/types'
import { UserState } from '../../metrics_server/user/types'
import { PitchesState } from '../../metrics_server/pitches/types'
import { useFormattedSession } from '../../metrics_server/sessions/hooks'
import { useAppDispatch } from '../../store/hooks'
import { setRedirect } from '../../ui/router/actions'
import { RootState } from '../../store'
import { getTeamPlayers } from '../../metrics_server/teams/actions'
import { useFormattedHardware } from '../../metrics_server/hardware/hooks'
import {
  addPlayerSession,
  getTeamsMostRecentSession
} from '../../metrics_server/sessions/actions'
import { postSeatConfigurationToDaemon } from '../../metrics_server/hardware/actions'

const actions = _.assign({}, teamActions, sessionActions, pitchActions)

export interface StartSessionContainerProps {
  sessions: SessionsState
  teams: TeamsState
  players: PlayersState
  user: UserState
  pitches: PitchesState

  bTeamActive: true

  updateSession
  setTeam
  setBTeam
  addPlayerSession
  setSession

  setRedirect
}

export const CreateSessionContainer: any = (ChildComponent) => {
  const ComposedComponent = (props: StartSessionContainerProps) => {
    const { pitches, players } = props

    const dispatch = useAppDispatch()

    const formattedSession = useFormattedSession('setup')

    const formattedHardware = useFormattedHardware()

    // If session team changes fetch players for that team
    const teamIdsRef = useRef<string[]>([])
    useEffect(() => {
      if (formattedSession) {
        formattedSession.teams.list.forEach((team) => {
          if (teamIdsRef.current.includes(team.id)) return
          dispatch(getTeamPlayers(team.id))
        })
        teamIdsRef.current = formattedSession.teams.list.map((team) => team.id)
      }
    }, [formattedSession, dispatch])

    // If officiating mode is selected, fetch players for officiation team
    useEffect(() => {
      if (
        formattedSession &&
        formattedSession.isOfficiatingMode &&
        formattedSession.officiationTeam
      ) {
        dispatch(getTeamPlayers(formattedSession.officiationTeam.id))
      }
    }, [formattedSession, dispatch])

    const submitSessionForm = useCallback(async () => {
      if (!formattedSession) return

      const { sport, teams, sessionData, type } = formattedSession

      // Post seat configuration to daemon - skip if no deamon option selected //
      if (!sessionData.noDaemon) {
        const response = await dispatch(
          postSeatConfigurationToDaemon(sessionData.mode)
        )
        if (!response) return
      }

      // Clear playersSessions and use each selected teams' most recent session's playersSessions
      // Use pitch in use if it matches the sportType
      dispatch(
        sessionActions.updateSession({
          playersSessions: [],
          pitch: pitches.inUse.type === sport.value ? pitches.inUse : null
        })
      )
      await Promise.all(
        teams.list.map(({ id: teamId }) => {
          return dispatch(
            getTeamsMostRecentSession(
              teamId,
              type.value,
              sport.value,
              (lastSession: RawSessionData) => {
                // Validate and format previous sessions playerSessions before adding them to the new one ///
                lastSession.playersSessions.forEach((ps) => {
                  const newPlayerSession = { ...ps }

                  // Remove id and sessionId from new player session playerSession
                  delete newPlayerSession.id
                  delete newPlayerSession.sessionId

                  // Check if player tag is available (online, sleep or offline) - if not remove tag
                  const isHardwareAvailable =
                    !!formattedHardware.devices.map[newPlayerSession.tag.id]
                  if (!isHardwareAvailable) {
                    delete newPlayerSession.tag
                  }

                  // Add player to session
                  if (
                    newPlayerSession.teamId === teamId &&
                    !!players.items[newPlayerSession.playerId]
                  ) {
                    dispatch(addPlayerSession(newPlayerSession))
                  }
                  // Add ball to session
                  else if (!newPlayerSession.playerId && newPlayerSession.tag) {
                    dispatch(addPlayerSession(newPlayerSession))
                  }
                })
              }
            )
          )
        })
      )

      dispatch(setRedirect(SessionSetupScreen.path))
    }, [pitches, formattedSession, dispatch])

    if (formattedSession) {
      return (
        <ChildComponent
          formattedSession={formattedSession}
          {...props}
          side={'right'}
          onSubmit={submitSessionForm}
        />
      )
    } else {
      return null
    }
  }

  function mapStateToProps(state: RootState) {
    return {
      players: state.players,
      sessions: state.sessions,
      pitches: state.pitches
    }
  }

  return connect(mapStateToProps, actions)(ComposedComponent)
}

export const toggleColors = {
  activeThumb: {
    base: 'rgb(250,250,250)'
  },
  inactiveThumb: {
    base: '#0099CC'
  },
  active: {
    base: '#3E3E3E',
    hover: '#3E3E3E'
  },
  inactive: {
    base: '#3E3E3E',
    hover: '#3E3E3E'
  }
}
