import { FormattedSession } from '../../sessions/types'
import { AussieRulesEventData, RawAussieRulesEventData } from './types'
import { AussieRulesEventFilters } from '../filter'
import { eventTypes } from '../data_types'
import { FormattedEventData } from '../types'
import { UnitSystem } from '../../units/types'
import { formatMetrics } from '../../metrics/functions'

export const filterAussieRulesEvents = (
  aussieRulesEvents: AussieRulesEventData[],
  { type }: AussieRulesEventFilters,
  formattedSession: FormattedSession
) => {
  const { australianRulesEventTypes } = formattedSession
  const filteredAussieRulesEvents = []
  aussieRulesEvents.forEach((aussieRulesEvent) => {
    if (eventTypes.isType('aussieRules', aussieRulesEvent.event?.type)) {
      const aussieRulesEventType = australianRulesEventTypes.getTypeByValue(
        aussieRulesEvent.type
      )

      if (type.value !== 'All') {
        if (!aussieRulesEventType) return
        if (
          !australianRulesEventTypes.isType(
            aussieRulesEventType.key,
            type.value
          )
        )
          return
      }

      const item = {
        typeName: aussieRulesEventType.name,
        type: aussieRulesEvent.type,
        id: `${aussieRulesEvent.id}-ausseRulesEvent`,
        teamLogo: formattedSession.teams.map[aussieRulesEvent.teamID] || {
          logo: '',
          color: 'black'
        },
        ...aussieRulesEvent
      }
      filteredAussieRulesEvents.push(item)
    }
  })
  return filteredAussieRulesEvents
}

export const generateFormattedAussieRulesData = (
  eventType: typeof eventTypes.items.aussieRules,
  aussieRulesEvent: RawAussieRulesEventData,
  formattedSession: FormattedSession,
  unitSystem: UnitSystem
): FormattedEventData => {
  const { teams, players, australianRulesEventTypes, startTime } =
    formattedSession
  const team = teams.map[aussieRulesEvent.teamID]
  const player =
    players?.[team ? team.id : 'all'].map[aussieRulesEvent.playerId]
  const sessionStartTime =
    (aussieRulesEvent.event.startTime - startTime.unixSeconds) * 1000

  const aussieRulesEventType = australianRulesEventTypes.getTypeByValue(
    aussieRulesEvent.type
  )
  const aussieRulesEventSubType =
    aussieRulesEventType.props.types &&
    !aussieRulesEventType.props.types.isEmpty()
      ? aussieRulesEventType.props.types.getTypeByValue(
          aussieRulesEvent.crossSection
        )
      : null

  // Get the 'main' type - Use sub type if it is valid (not unknown) / not null - otherwise use the type //
  const mainType =
    aussieRulesEventSubType && !aussieRulesEventSubType.isUnknown()
      ? aussieRulesEventSubType
      : aussieRulesEventType

  // metrics //
  const metrics = formatMetrics(
    aussieRulesEventType.props.metricTypes,
    aussieRulesEvent,
    formattedSession,
    unitSystem
  )

  const operator = aussieRulesEvent.operatorNotes
    ? {
        id: aussieRulesEvent.operatorNotes.id,
        notes: aussieRulesEvent.operatorNotes.notes,
        highlight: aussieRulesEvent.operatorNotes.highlight,
        matchTime: aussieRulesEvent.operatorNotes.matchTime
      }
    : null

  const formattedAussieRulesEvent = {
    id: aussieRulesEvent.id,
    rawData: aussieRulesEvent,
    eventType: eventType.value,
    sessionId: aussieRulesEvent.event.sessionId,
    startTime: aussieRulesEvent.event.startTime,
    sessionStartTime,
    ignore: aussieRulesEvent.ignore,
    endTime: aussieRulesEvent.event.endTime,
    typeName: mainType.name,
    type: {
      selected: aussieRulesEventType,
      options: australianRulesEventTypes?.options
    },
    subType: null,
    team: {
      selected: team,
      options: teams?.optionsWithNull
    },
    player: {
      selected: player,
      options: players?.[team ? team.id : 'all'].optionsWithNull
    },
    metrics,

    compareTagPosition: {
      label: 'X Distance to touch',
      ...aussieRulesEvent.position
    },

    features: aussieRulesEventType.props.features,

    operator
  }
  return formattedAussieRulesEvent
}
