import { generateFormattedAussieRulesData } from './aussie_rules/functions'
import { eventTypes, isEventType } from './data_types'
import { generateFormattedFlightData } from './flight/functions'
import { FormattedEventData, RawEventData } from './types'
import {
  generateCodingTableGameEventRowData,
  generateFormattedGameData
} from './game/functions'
import {
  generateCodingTableTimeEventRowData,
  generateFormattedTimeData
} from './time/functions'
import { GameEventData } from './game/types'
import { RawFlightEventData } from './flight/types'
import { TimeEventData } from './time/types'
import {
  AussieRulesEventData,
  RawAussieRulesEventData
} from './aussie_rules/types'
import { FormattedSession } from '../sessions/types'
import { UnitSystem } from '../units/types'
import { TableRow } from '../../components/Table/Table.types'
import { generateMetricTypeClass } from '../metrics/player_data_types'
import { MetricTypeKeys } from '../metrics/data_types'

export const isEventTypeData = {
  game: (eventData): eventData is GameEventData => {
    if (
      eventData.event &&
      eventData.event.type === eventTypes.items.game.value
    ) {
      return true
    }
    return false
  },
  flight: (eventData): eventData is RawFlightEventData => {
    if (
      eventData.event &&
      eventData.event.type === eventTypes.items.flight.value
    ) {
      return true
    }
    return false
  },
  time: (eventData): eventData is TimeEventData => {
    if (
      eventData.event &&
      eventData.event.type === eventTypes.items.time.value
    ) {
      return true
    }
    return false
  },
  aussieRules: (eventData): eventData is RawAussieRulesEventData => {
    if (
      eventData.event &&
      eventData.event.type === eventTypes.items.aussieRules.value
    ) {
      return true
    }
    return false
  }
}

export const generateEventTableData = (
  formattedEventData: FormattedEventData[],
  formattedSession: FormattedSession,
  unitSystem: UnitSystem
) => {
  const tableData = formattedEventData.map((formattedEvent) => {
    const row = generateEventRowData(
      formattedEvent,
      formattedSession,
      unitSystem
    )
    return row
  })
  return tableData
}

export const generateEventRowData = (
  formattedEventData: FormattedEventData,
  formattedSession: FormattedSession,
  unitSystem: UnitSystem
) => {
  const { isMatchMode } = formattedSession
  const {
    metrics,
    player,
    team,
    type,
    subType,
    outcome,
    startTime,
    endTime,
    operator,
    id,
    ignore
  } = formattedEventData
  const row = {} as TableRow
  row.id = id
  if (metrics) {
    for (const metric in metrics) {
      row[metric] = metrics[metric].formattedValue
    }
  }

  // Player Row
  if (player?.selected) {
    const { number, firstName, lastName } = player.selected
    if (isMatchMode) {
      row.fromPlayer = `${number ? number : ''}. ${firstName} ${lastName}`
    } else {
      row.fromPlayer = `${firstName.split('')[0]}. ${lastName}`
    }
  } else {
    row.fromPlayer = ``
  }

  // Team Row
  if (team?.selected) {
    const { color, logo } = team.selected
    row.teamLogo = { color, logo }
  } else {
    row.teamLogo = {}
  }

  // Type Row
  let typeName
  if (subType?.selected.name === 'Unclassified') {
    typeName = type.selected.name
  } else if (subType?.selected.name) {
    typeName = subType?.selected.name
  } else {
    typeName = type.selected.name
  }
  row.typeName = typeName

  // Outcome Row
  if (outcome) {
    row.outcome = outcome.selected?.name
  }

  // Operator Row
  if (operator) {
    row.highlight = operator.highlight
  }

  row.sessionStartTime = startTime * 1000 - formattedSession.startTime.unixMil
  row.startTime = startTime
  row.startTimeMil = startTime * 1000
  row.endTime = endTime

  row.ignore = ignore
  if (ignore) {
    row.__color = '#D3D3D3'
    row.__backgroundColor = undefined
  }

  return row
}

export const generateCodingTableEventRowData = (data, sessionConfig) => {
  let tableData = []
  data.forEach((event) => {
    switch (event.event?.type) {
      case 1:
        tableData = [
          ...generateCodingTableTimeEventRowData(event, data, sessionConfig),
          ...tableData
        ]
        break
      case 6:
        tableData = [
          generateCodingTableGameEventRowData(event, sessionConfig),
          ...tableData
        ]
        break
      default:
        break
    }
  })
  return tableData
}

export const formatEventData = (
  event: RawEventData,
  formattedSession: FormattedSession,
  unitSystem: UnitSystem
): FormattedEventData => {
  if (event.sessionId && event.sessionId !== formattedSession.id) return null
  if (event.event?.sessionId !== formattedSession.id) return null

  const eventType = formattedSession.eventTypes.getTypeByValue(event.event.type)
  if (isEventType.flight(eventType) && isEventTypeData.flight(event)) {
    return generateFormattedFlightData(
      eventType,
      event,
      formattedSession,
      unitSystem
    )
  } else if (isEventType.game(eventType) && isEventTypeData.game(event)) {
    return generateFormattedGameData(
      eventType,
      event,
      formattedSession,
      unitSystem
    )
  } else if (
    isEventType.aussieRules(eventType) &&
    isEventTypeData.aussieRules(event)
  ) {
    return generateFormattedAussieRulesData(
      eventType,
      event,
      formattedSession,
      unitSystem
    )
  } else if (isEventType.time(eventType) && isEventTypeData.time(event)) {
    return generateFormattedTimeData(eventType, event, formattedSession)
  } else {
    throw new Error(`Unrecognized event type: ${event.type}`)
  }
}
