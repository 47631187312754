import { BROADCAST_ROOT_URL } from '../../const'
import {
  SET_FLIGHT_PUBLISHED,
  TOGGLE_AUTO_BROADCASTING,
  UPDATE_BROADCAST_STATE
} from './types'
import { request } from '../../utils/request_handler'
import { setError } from '../../ui/error/actions'
import { getSessionGameEvents } from '../events/actions'

export function toggleAutoBroadcasting(bool) {
  return (dispatch) => {
    const options = {
      url: `broadcasting/${bool ? 'enable' : 'disable'}`,
      method: 'post',
      baseURL: BROADCAST_ROOT_URL,
      withCredentials: true
    }
    const success = () => {
      dispatch({
        type: TOGGLE_AUTO_BROADCASTING,
        payload: bool
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to toggle mqtt broadcasting. API request failed. Check log for more detail.`
        })
      )
    }
    return request(options, success, error, dispatch)
  }
}

export function manualPublish(flight, callback) {
  return (dispatch) => {
    const data = { ...flight }

    const success = (response) => {
      dispatch({ type: SET_FLIGHT_PUBLISHED, payload: flight.id })
      callback()
    }

    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to manually publish Flight. API request failed. Check log for more detail.`
        })
      )
      callback()
    }

    manualPublishRequest(data, dispatch, success, error, flight.id)
  }
}

function manualPublishRequest(data, dispatch, success, error, flightId) {
  const options = {
    url: `manualPublish/${flightId}`,
    data: data,
    method: 'put',
    baseURL: BROADCAST_ROOT_URL,
    withCredentials: true
  }
  return request(options, success, error, dispatch)
}

export function updateBroadcastState(state) {
  return {
    type: UPDATE_BROADCAST_STATE,
    payload: state
  }
}

export function getBroadcastState() {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: UPDATE_BROADCAST_STATE,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get broadcast state. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `getBroadcastState`,
      method: 'get',
      baseURL: BROADCAST_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error)
  }
}

export function switchTeamSides() {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: UPDATE_BROADCAST_STATE,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to switch sides. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `switchTeamSides`,
      method: 'post',
      data: {},
      baseURL: BROADCAST_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error)
  }
}

export function switchPossession(data) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: UPDATE_BROADCAST_STATE,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to change possession. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `changePossession`,
      method: 'post',
      data,
      baseURL: BROADCAST_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error)
  }
}
