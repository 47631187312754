import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  TypesJson,
  DataTypeConfig,
  getTypesConfigFromJson
} from '../data_types'

export type OutcomeTypeFeatures = {
  exampleOutcomeFeature: boolean

  isSettable: boolean
}

export const defaultOutcomeTypeFeatures: OutcomeTypeFeatures = {
  exampleOutcomeFeature: true,

  isSettable: true
}

export const outcomeTypesConfig = {
  none: {
    key: 'none',
    value: 0,
    name: '-',
    props: { features: defaultOutcomeTypeFeatures }
  },

  // Kick //
  retained: {
    key: 'retained',
    value: 1,
    name: 'Retained',
    props: { features: defaultOutcomeTypeFeatures }
  },
  contestedLost: {
    key: 'contestedLost',
    value: 2,
    name: 'Contested Lost',
    props: { features: defaultOutcomeTypeFeatures }
  },
  uncontested: {
    key: 'uncontested',
    value: 3,
    name: 'Uncontested',
    props: { features: defaultOutcomeTypeFeatures }
  },

  // Lineout //
  won: {
    key: 'won',
    value: 4,
    name: 'Won',
    props: { features: defaultOutcomeTypeFeatures }
  },
  lost: {
    key: 'lost',
    value: 5,
    name: 'Lost',
    props: { features: defaultOutcomeTypeFeatures }
  },
  maul: {
    key: 'maul',
    value: 22,
    name: 'Maul',
    props: { features: defaultOutcomeTypeFeatures }
  },
  penalty: {
    key: 'penalty',
    value: 23,
    name: 'Penalty',
    props: { features: defaultOutcomeTypeFeatures }
  },
  notStraight: {
    key: 'notStraight',
    value: 24,
    name: 'Not Straight',
    props: { features: defaultOutcomeTypeFeatures }
  },

  // ===== //
  returned: {
    key: 'returned',
    value: 6,
    name: 'Returned',
    props: { features: defaultOutcomeTypeFeatures }
  },
  rouge: {
    key: 'rouge',
    value: 7,
    name: 'Rouge',
    props: { features: defaultOutcomeTypeFeatures }
  },
  outOfBounds: {
    key: 'outOfBounds',
    value: 8,
    name: 'Out Of Bounds',
    props: { features: defaultOutcomeTypeFeatures }
  },
  recoveredReceivingTeam: {
    key: 'recoveredReceivingTeam',
    value: 9,
    name: 'Recovered Receiving Team',
    props: { features: defaultOutcomeTypeFeatures }
  },
  recoveredKickingTeam: {
    key: 'recoveredKickingTeam',
    value: 10,
    name: 'Recovered Kicking Team',
    props: { features: defaultOutcomeTypeFeatures }
  },
  blocked: {
    key: 'blocked',
    value: 11,
    name: 'Blocked',
    props: { features: defaultOutcomeTypeFeatures }
  },
  good: {
    key: 'good',
    value: 12,
    name: 'Good',
    props: { features: defaultOutcomeTypeFeatures }
  },
  noGood: {
    key: 'noGood',
    value: 13,
    name: 'No Good',
    props: { features: defaultOutcomeTypeFeatures }
  },
  onSideKickRecovered: {
    key: 'onSideKickRecovered',
    value: 14,
    name: 'On Side Kick Recovered',
    props: { features: defaultOutcomeTypeFeatures }
  },
  complete: {
    key: 'complete',
    value: 15,
    name: 'Complete',
    props: { features: defaultOutcomeTypeFeatures }
  },
  incomplete: {
    key: 'incomplete',
    value: 16,
    name: 'Incomplete',
    props: { features: defaultOutcomeTypeFeatures }
  },
  touchdown: {
    key: 'touchdown',
    value: 17,
    name: 'Touchdown',
    props: { features: defaultOutcomeTypeFeatures }
  },
  interception: {
    key: 'interception',
    value: 18,
    name: 'Interception',
    props: { features: defaultOutcomeTypeFeatures }
  },

  // ===== Soccer ====== //
  goal: {
    key: 'goal',
    value: 19,
    name: 'Not Straight',
    props: { features: defaultOutcomeTypeFeatures }
  },
  missedShot: {
    key: 'missedShot',
    value: 21,
    name: 'Missed Shot',
    props: {
      features: {
        ...defaultOutcomeTypeFeatures,
        isSettable: false
      }
    }
  },
  blockedOrSaved: {
    key: 'blockedOrSaved',
    value: 25,
    name: 'Blocked',
    props: { features: defaultOutcomeTypeFeatures }
  },
  missedWide: {
    key: 'missedWide',
    value: 26,
    name: 'Missed Wide',
    props: { features: defaultOutcomeTypeFeatures }
  },
  missedOverCrossbar: {
    key: 'missedOverCrossbar',
    value: 27,
    name: 'Missed Over Crossbar',
    props: { features: defaultOutcomeTypeFeatures }
  }
} as const

export class OutcomeTypeProps {
  features: OutcomeTypeFeatures
}

export type OutcomeTypes = DataTypes<typeof outcomeTypesConfig>

export type OutcomeTypeKey = DataTypeKey<OutcomeTypes>

export type OutcomeTypeKeys = DataTypeKeys<OutcomeTypeKey>

export type OutcomeTypeValues = DataTypeValues<OutcomeTypeKey>

export const getOutcomeTypeGroup = (items: {
  [key in OutcomeTypeKeys]?: OutcomeTypes[key]
}) => {
  return getDataTypeGroup<
    OutcomeTypeKeys,
    OutcomeTypeValues,
    OutcomeTypes,
    OutcomeTypeProps
  >(items)
}

export type OutcomeTypeGroup = ReturnType<typeof getOutcomeTypeGroup>

export type OutcomeTypeConfig = DataTypeConfig<
  OutcomeTypeKeys,
  OutcomeTypeValues,
  OutcomeTypeProps
>

export const outcomeTypes = getOutcomeTypeGroup(outcomeTypesConfig)

export type OutcomeType = ReturnType<typeof outcomeTypes.getTypeByValue>

export const isOutcomeType = generateTypeChecks<
  OutcomeTypeKeys,
  OutcomeTypeGroup
>(outcomeTypes)

export type OutcomeTypeJson = {
  key: OutcomeTypeKeys
  name: string
  abbr: string
}

export type OutcomeTypesJson = TypesJson<OutcomeTypeKeys, OutcomeTypeJson>

export function getOutcomeTypesConfigFromJson(
  typesJson: (
    | string
    | {
        key: string
        name?: string
        abbr?: string
      }
  )[] = [],
  typesConfig: Partial<typeof outcomeTypesConfig> = {}
) {
  return getTypesConfigFromJson<string, typeof typesConfig>(
    typesJson,
    typesConfig,
    outcomeTypesConfig
  )
}
